<template>
  <div id="notes-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/notes" class="btn btn-secondary mb-5 mr-3">Zurück zur Übersicht</router-link>
    <button @click="save" class="btn btn-primary mb-5">Notiz speichern</button>

    <div class="card p-3 mb-4" :style="'background:' + newNote.color">
      <h3>Text eingeben:</h3>
      <textarea v-model="newNote.text" class="form-control"></textarea>
      <hr>
      <h3>Vorschau:</h3>
      <div v-html="newNote.text"></div>

      <hr class="mb-1">
      <div class="row text-muted">
        <div class="col-3">
          <p class="small mb-0 mt-1">{{ newCreatedAt }}</p>
        </div>
        <div class="col-3">
          <input type="color" v-model="newNote.color" placeholder="Farbe eingeben" class="form-control form-control-sm">
        </div>
        <div class="col-3">
          <input type="text" @change="addTag($event)" placeholder="Neuen Tag hinzufügen" class="form-control form-control-sm">
        </div>
        <div class="col-3">
          <div class="text-right">
            <span v-for="(tag, index) in newNote.tags" @click="removeTag(index)" class="badge badge-dark" :key="index">
              {{ tag }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <button @click="save" class="btn btn-block btn-primary">
      Notiz speichern
    </button>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NotesEdit',
  components: {
  },
  data() {
    return {
      newNote: {
        id: "",
        text: "",
        tags: [],
        createdAt: +Date.now(),
        color: "#e0e0e0",
      },
    }
  },
  computed: {
    notes() {
      return this.project.notes
          .map(note => {
            note.createdAt = new Date(note.createdAt).toISOString().slice(0, 10);
            return note;
          })
          .filter(note => !this.filter || note.tags.find(t => t === this.filter));
    },
    newCreatedAt() {
      return new Date(this.newNote.createdAt).toISOString().slice(0, 10);
    }
  },
  methods: {
    addTag($event) {
      this.newNote.tags.push($event.target.value);
      $event.target.value = "";
    },
    removeTag(index) {
      this.newNote.tags.splice(index, 1);
    },
    save() {
      if (this.$route.params.id) {
        let index = this.project.notes.findIndex(a => a.id === this.newNote.id);
        this.project.notes.splice(index, 1, this.newNote);
      } else {
        this.project.notes.push(this.newNote);
      }
      this.$store.dispatch('project/updateProjectByProp', {prop: 'notes', data: this.project.notes})
          .then(res => {
            console.debug(res);
            this.$router.push('/notes');
          });
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.newNote = this.notes.find(n => n.id === this.$route.params.id);
    } else {
      this.newNote.id = this.generateId();
    }
  }
}
</script>
